var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        {
          staticClass: "pb-0",
          staticStyle: { "max-width": "1600px" },
          attrs: { fluid: "" },
        },
        [
          _c(
            "v-expansion-panels",
            [
              _c(
                "v-expansion-panel",
                [
                  _c(
                    "v-expansion-panel-header",
                    { attrs: { "data-cy": "expand" } },
                    [_vm._v(" Search ")]
                  ),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0 pb-0",
                              attrs: {
                                cols: "12",
                                lg: "2",
                                md: "2",
                                sm: "6",
                                xl: "2",
                              },
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.loadStatusAll,
                                  clearable: "",
                                  "data-cy": "load-status",
                                  "item-text": "name",
                                  "item-value": "id",
                                  label: "Load Status",
                                },
                                model: {
                                  value: _vm.searchParam.loadStatusFilter,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.searchParam,
                                      "loadStatusFilter",
                                      $$v
                                    )
                                  },
                                  expression: "searchParam.loadStatusFilter",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0 pb-0",
                              attrs: {
                                cols: "12",
                                lg: "2",
                                md: "2",
                                sm: "6",
                                xl: "2",
                              },
                            },
                            [
                              _c("customer-auto-complete", {
                                staticClass: "pt-3",
                                attrs: { "data-cy": "customer" },
                                on: { event: _vm.selectedCustomer },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0 pb-0",
                              attrs: {
                                cols: "12",
                                lg: "2",
                                md: "2",
                                sm: "6",
                                xl: "2",
                              },
                            },
                            [
                              _c("carrier-auto-complete", {
                                staticClass: "pt-3",
                                attrs: {
                                  "carrier-text": "Carrier",
                                  "data-cy": "carrier",
                                },
                                on: { event: _vm.selectedCarrier },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0 pb-0",
                              attrs: {
                                cols: "12",
                                sm: "6",
                                md: "2",
                                lg: "2",
                                xl: "2",
                              },
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  type: "text",
                                  label: "Carrier Invoice Number",
                                },
                                on: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.searchAllLoads()
                                  },
                                },
                                model: {
                                  value: _vm.searchParam.carrierInvoiceNumber,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.searchParam,
                                      "carrierInvoiceNumber",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "searchParam.carrierInvoiceNumber",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0 pb-0",
                              attrs: {
                                cols: "12",
                                lg: "2",
                                md: "2",
                                sm: "6",
                                xl: "2",
                              },
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.equipmentTypes,
                                  clearable: "",
                                  "data-cy": "equipment",
                                  "item-text": "name",
                                  "item-value": "id",
                                  label: "Equipment Type",
                                },
                                model: {
                                  value: _vm.searchParam.equipmentFilter,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.searchParam,
                                      "equipmentFilter",
                                      $$v
                                    )
                                  },
                                  expression: "searchParam.equipmentFilter",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0 pb-0",
                              attrs: {
                                cols: "12",
                                lg: "2",
                                md: "2",
                                sm: "6",
                                xl: "2",
                              },
                            },
                            [
                              _c("agent-auto-complete", {
                                attrs: {
                                  "agent-text": "Agent Name",
                                  "data-cy": "agent",
                                },
                                on: { event: _vm.selectedAgent },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0 pb-0",
                              attrs: {
                                cols: "12",
                                lg: "2",
                                md: "2",
                                sm: "6",
                                xl: "2",
                              },
                            },
                            [
                              _c("carrier-sales-rep-auto-complete", {
                                attrs: { "data-cy": "carrier-sales" },
                                on: { event: _vm.selectedCSR },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0 pb-0",
                              attrs: {
                                cols: "12",
                                sm: "6",
                                md: "2",
                                lg: "2",
                                xl: "2",
                              },
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.holdItems,
                                  clearable: "",
                                  "data-cy": "hold",
                                  "item-text": "name",
                                  "item-value": "id",
                                  label: "Hold",
                                },
                                model: {
                                  value: _vm.searchParam.hold,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchParam, "hold", $$v)
                                  },
                                  expression: "searchParam.hold",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0 pb-0",
                              attrs: {
                                cols: "12",
                                sm: "6",
                                md: "2",
                                lg: "2",
                                xl: "2",
                              },
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.orderTypes,
                                  clearable: "",
                                  "data-cy": "order-type",
                                  "item-text": "name",
                                  "item-value": "id",
                                  label: "Order Type",
                                },
                                model: {
                                  value: _vm.searchParam.orderType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchParam, "orderType", $$v)
                                  },
                                  expression: "searchParam.orderType",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0 pb-0",
                              attrs: {
                                cols: "12",
                                lg: "2",
                                md: "2",
                                sm: "6",
                                xl: "2",
                              },
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "data-cy": "load-number",
                                  label: "Load Number",
                                  type: "text",
                                },
                                on: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.searchAllLoads()
                                  },
                                },
                                model: {
                                  value: _vm.searchParam.loadNumberFilter,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.searchParam,
                                      "loadNumberFilter",
                                      $$v
                                    )
                                  },
                                  expression: "searchParam.loadNumberFilter",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0 pb-0",
                              attrs: {
                                cols: "12",
                                lg: "2",
                                md: "2",
                                sm: "6",
                                xl: "2",
                              },
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "data-cy": "ref-id",
                                  label: "Customer Ref Id",
                                  type: "text",
                                },
                                on: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.searchAllLoads()
                                  },
                                },
                                model: {
                                  value: _vm.searchParam.CustomerRefId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.searchParam,
                                      "CustomerRefId",
                                      $$v
                                    )
                                  },
                                  expression: "searchParam.CustomerRefId",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0 pb-0",
                              attrs: {
                                cols: "12",
                                lg: "2",
                                md: "2",
                                sm: "6",
                                xl: "2",
                              },
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "data-cy": "po-number",
                                  label: "PO Number",
                                  type: "text",
                                },
                                on: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.searchAllLoads()
                                  },
                                },
                                model: {
                                  value: _vm.searchParam.poNumberFilter,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.searchParam,
                                      "poNumberFilter",
                                      $$v
                                    )
                                  },
                                  expression: "searchParam.poNumberFilter",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0 pb-0",
                              attrs: {
                                cols: "12",
                                lg: "2",
                                md: "2",
                                sm: "6",
                                xl: "2",
                              },
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "data-cy": "pu-number",
                                  label: "PU Number",
                                  type: "text",
                                },
                                on: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.searchAllLoads()
                                  },
                                },
                                model: {
                                  value: _vm.searchParam.puNumberFilter,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.searchParam,
                                      "puNumberFilter",
                                      $$v
                                    )
                                  },
                                  expression: "searchParam.puNumberFilter",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0 pb-0",
                              attrs: {
                                cols: "12",
                                lg: "2",
                                md: "2",
                                sm: "6",
                                xl: "2",
                              },
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "data-cy": "commodity",
                                  label: "Commodity",
                                  type: "text",
                                },
                                on: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.searchAllLoads()
                                  },
                                },
                                model: {
                                  value: _vm.searchParam.commodityFilter,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.searchParam,
                                      "commodityFilter",
                                      $$v
                                    )
                                  },
                                  expression: "searchParam.commodityFilter",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0 pb-0",
                              attrs: {
                                cols: "12",
                                sm: "6",
                                md: "2",
                                lg: "2",
                                xl: "2",
                              },
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "data-cy": "origin-city",
                                  label: "Origin City",
                                  type: "text",
                                },
                                on: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.searchAllLoads()
                                  },
                                },
                                model: {
                                  value: _vm.searchParam.originFilter.city,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.searchParam.originFilter,
                                      "city",
                                      $$v
                                    )
                                  },
                                  expression: "searchParam.originFilter.city",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0 pb-0",
                              attrs: {
                                cols: "12",
                                lg: "2",
                                md: "2",
                                sm: "6",
                                xl: "2",
                              },
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "data-cy": "origin-state",
                                  hint: "Short state name (Ex. Texas - TX)",
                                  label: "Origin State",
                                  "persistent-hint": "",
                                  type: "text",
                                },
                                on: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.searchAllLoads()
                                  },
                                },
                                model: {
                                  value: _vm.searchParam.originFilter.state,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.searchParam.originFilter,
                                      "state",
                                      $$v
                                    )
                                  },
                                  expression: "searchParam.originFilter.state",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0 pb-0",
                              attrs: {
                                cols: "12",
                                lg: "2",
                                md: "2",
                                sm: "6",
                                xl: "2",
                              },
                            },
                            [
                              _c(
                                "v-menu",
                                {
                                  attrs: {
                                    "close-on-content-click": false,
                                    "data-cy": "pickup-date",
                                    "offset-y": "",
                                    transition: "scale-transition",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-text-field",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    label: "Pickup Date",
                                                    "persistent-hint": "",
                                                    readonly: "",
                                                    "data-cy":
                                                      "pickup-date-field",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.pickupDateCalender,
                                                    callback: function ($$v) {
                                                      _vm.pickupDateCalender =
                                                        $$v
                                                    },
                                                    expression:
                                                      "pickupDateCalender",
                                                  },
                                                },
                                                "v-text-field",
                                                attrs,
                                                false
                                              ),
                                              on
                                            )
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.pickupCalendar,
                                    callback: function ($$v) {
                                      _vm.pickupCalendar = $$v
                                    },
                                    expression: "pickupCalendar",
                                  },
                                },
                                [
                                  _c("v-date-picker", {
                                    attrs: {
                                      "no-title": "",
                                      "data-cy": "pickup-date-picker",
                                    },
                                    on: {
                                      input: function ($event) {
                                        _vm.pickupCalendar = false
                                      },
                                    },
                                    model: {
                                      value: _vm.searchParam.pickupDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchParam,
                                          "pickupDate",
                                          $$v
                                        )
                                      },
                                      expression: "searchParam.pickupDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0 pb-0",
                              attrs: {
                                cols: "12",
                                lg: "2",
                                md: "2",
                                sm: "6",
                                xl: "2",
                              },
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "data-cy": "destination-city",
                                  label: "Destination City",
                                  type: "text",
                                },
                                on: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.searchAllLoads()
                                  },
                                },
                                model: {
                                  value: _vm.searchParam.destinationFilter.city,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.searchParam.destinationFilter,
                                      "city",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "searchParam.destinationFilter.city",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0 pb-0",
                              attrs: {
                                cols: "12",
                                lg: "2",
                                md: "2",
                                sm: "6",
                                xl: "2",
                              },
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "data-cy": "destination-state",
                                  hint: "Short state name (Ex. Texas - TX)",
                                  label: "Destination State",
                                  "persistent-hint": "",
                                  type: "text",
                                },
                                on: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.searchAllLoads()
                                  },
                                },
                                model: {
                                  value:
                                    _vm.searchParam.destinationFilter.state,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.searchParam.destinationFilter,
                                      "state",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "searchParam.destinationFilter.state",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0 pb-0",
                              attrs: {
                                cols: "12",
                                lg: "2",
                                md: "2",
                                sm: "6",
                                xl: "2",
                              },
                            },
                            [
                              _c(
                                "v-menu",
                                {
                                  attrs: {
                                    "close-on-content-click": false,
                                    "offset-y": "",
                                    transition: "scale-transition",
                                    "data-cy": "delivery-date-picker",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-text-field",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    label: "Delivery Date",
                                                    "persistent-hint": "",
                                                    readonly: "",
                                                    "data-cy":
                                                      "delivery-date-field",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.deliveryDateCalender,
                                                    callback: function ($$v) {
                                                      _vm.deliveryDateCalender =
                                                        $$v
                                                    },
                                                    expression:
                                                      "deliveryDateCalender",
                                                  },
                                                },
                                                "v-text-field",
                                                attrs,
                                                false
                                              ),
                                              on
                                            )
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.deliveryCalendar,
                                    callback: function ($$v) {
                                      _vm.deliveryCalendar = $$v
                                    },
                                    expression: "deliveryCalendar",
                                  },
                                },
                                [
                                  _c("v-date-picker", {
                                    attrs: {
                                      "no-title": "",
                                      "data-cy": "delivery-date-field",
                                    },
                                    on: {
                                      input: function ($event) {
                                        _vm.deliveryCalendar = false
                                      },
                                    },
                                    model: {
                                      value: _vm.searchParam.deliveryDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchParam,
                                          "deliveryDate",
                                          $$v
                                        )
                                      },
                                      expression: "searchParam.deliveryDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0 pb-0",
                              attrs: {
                                cols: "12",
                                lg: "2",
                                md: "2",
                                sm: "6",
                                xl: "2",
                              },
                            },
                            [
                              _c("account-manager-auto-complete", {
                                ref: "account-manager",
                                attrs: {
                                  color: "#e1b753",
                                  "default-manager": _vm.searchParam.manager,
                                },
                                on: {
                                  event: _vm.selectedAccountManager,
                                  blur: _vm.selectedAccountManager,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "flex", attrs: { cols: "12" } },
                            [
                              _c(
                                "Button",
                                {
                                  staticClass: "mr-2",
                                  attrs: { primary: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.searchAllLoads()
                                    },
                                  },
                                },
                                [_vm._v(" Filter ")]
                              ),
                              _c(
                                "Button",
                                {
                                  staticClass: "mr-2",
                                  on: {
                                    click: function ($event) {
                                      return _vm.resetSearch()
                                    },
                                  },
                                },
                                [_vm._v("Reset")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-container",
        {
          staticClass: "pt-0 pb-0",
          staticStyle: { "max-width": "1600px" },
          attrs: { fluid: "" },
        },
        [
          _c("load-tracking"),
          _c(
            "v-card",
            { staticClass: "pt-0 pb-0" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0",
                      attrs: {
                        cols: _vm.$vuetify.breakpoint.mdAndUp
                          ? _vm.columnNumber
                          : 12,
                      },
                    },
                    [
                      _c(
                        "v-tabs",
                        {
                          attrs: { grow: "" },
                          on: { change: _vm.changeTab },
                          model: {
                            value: _vm.currentTab,
                            callback: function ($$v) {
                              _vm.currentTab = $$v
                            },
                            expression: "currentTab",
                          },
                        },
                        _vm._l(_vm.tabs, function (i) {
                          return _c(
                            "v-tab",
                            {
                              key: i.href,
                              attrs: {
                                disabled: _vm.loadingIcon,
                                href: `#tab-${i.href}`,
                              },
                            },
                            [
                              _vm._v(" " + _vm._s(i.title) + " "),
                              _vm.currentTab.split("-")[1] === i.href.toString()
                                ? _c("span", [
                                    _vm._v("(" + _vm._s(i.count) + ")"),
                                  ])
                                : _vm._e(),
                            ]
                          )
                        }),
                        1
                      ),
                      _c(
                        "v-tabs-items",
                        {
                          staticStyle: {
                            "overflow-y": "auto",
                            "max-height": "680px",
                          },
                          attrs: { id: "tabData" },
                          model: {
                            value: _vm.currentTab,
                            callback: function ($$v) {
                              _vm.currentTab = $$v
                            },
                            expression: "currentTab",
                          },
                        },
                        _vm._l(_vm.tabs, function (i) {
                          return _c(
                            "v-tab-item",
                            { key: i.href, attrs: { value: `tab-${i.href}` } },
                            [
                              _c("v-data-table", {
                                attrs: {
                                  id: `loadsTable-${i.href}`,
                                  headers: _vm.headers,
                                  "item-class": _vm.itemRowBackground,
                                  items: _vm.loadItems,
                                  "items-per-page": _vm.pageSize,
                                  loading: _vm.loadingIcon,
                                  "calculate-widths": "",
                                  "fixed-header": "",
                                  "hide-default-footer": "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "item.customer",
                                      fn: function ({ item }) {
                                        return [
                                          _c(
                                            "p",
                                            {
                                              staticStyle: {
                                                "text-align": "center",
                                                cursor: "pointer",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.loadClick(item)
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.customer) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "item.loadNumber",
                                      fn: function ({ item }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                to: {
                                                  name: "Load Detail",
                                                  params: {
                                                    id: item.loadNumber,
                                                  },
                                                },
                                                small: "",
                                                target: "_blank",
                                                text: "",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.loadNumber) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c("br"),
                                          item.customerReferenceId
                                            ? _c("tooltip", {
                                                attrs: {
                                                  "tooltip-content": `Ref: ${item.customerReferenceId}`,
                                                  type: "td",
                                                },
                                              })
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                    {
                                      key: "item.loadStatus",
                                      fn: function ({ item }) {
                                        return [
                                          _c("load-status-button", {
                                            attrs: {
                                              "agent-detail": _vm.agentDetail,
                                              "load-status-items":
                                                _vm.loadStatusChoices,
                                              "shipment-detail": item,
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                    {
                                      key: "item.carrier",
                                      fn: function ({ item }) {
                                        return [
                                          item.carrier
                                            ? _c(
                                                "p",
                                                [
                                                  _c(
                                                    "router-link",
                                                    {
                                                      attrs: {
                                                        to: {
                                                          name: "Carrier Detail",
                                                          params: {
                                                            id: item.carrier.id,
                                                            title: `${item.carrier.name} - ${item.carrier.mc_number}`,
                                                          },
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color: "#464646",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.carrier
                                                                  .name
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                    {
                                      key: "item.loadboards",
                                      fn: function ({ item }) {
                                        return [
                                          _c("loadboard-manager", {
                                            attrs: {
                                              "load-id": item.loadNumber,
                                              "loadboard-post-statuses":
                                                item.loadboardPostStatuses,
                                              "load-status": item.loadStatus,
                                            },
                                            on: {
                                              "update-statuses": function (
                                                $event
                                              ) {
                                                return _vm.handleUpdatedStatuses(
                                                  item.loadNumber,
                                                  $event
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                    {
                                      key: "item.cost",
                                      fn: function ({ item }) {
                                        return [
                                          _c("p", [
                                            item.cost.min
                                              ? _c("span", [_vm._v("Min: ")])
                                              : _vm._e(),
                                            item.cost.min
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "blue--text text--darken",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "$" +
                                                        _vm._s(item.cost.min)
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c("br"),
                                            item.cost.max
                                              ? _c("span", [_vm._v("Max: ")])
                                              : _vm._e(),
                                            item.cost.max
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "blue--text text--darken",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "$" +
                                                        _vm._s(item.cost.max)
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]),
                                        ]
                                      },
                                    },
                                    {
                                      key: "item.shipper",
                                      fn: function ({ item }) {
                                        return [
                                          item.shipper !== null
                                            ? _c(
                                                "p",
                                                { staticClass: "mb-0" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.formatLocation(
                                                          item.shipper.city,
                                                          item.shipper
                                                            .state_province_region
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                  _c("br"),
                                                  item.pickupDate !== null
                                                    ? [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.formatDate(
                                                                item.pickupDate
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    : _vm._e(),
                                                  item.pickupEarlyTime !== null
                                                    ? [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.parseTime(
                                                                item.pickupEarlyTime
                                                              )
                                                            ) +
                                                            " - " +
                                                            _vm._s(
                                                              _vm.parseTime(
                                                                item.pickupLateTime
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    : _vm._e(),
                                                ],
                                                2
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                    {
                                      key: "item.consignee",
                                      fn: function ({ item }) {
                                        return [
                                          item.consignee !== null
                                            ? _c(
                                                "p",
                                                { staticClass: "mb-0" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.formatLocation(
                                                          item.consignee.city,
                                                          item.consignee
                                                            .state_province_region
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                  _c("br"),
                                                  item.deliveryDate !== null
                                                    ? [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.formatDate(
                                                                item.deliveryDate
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    : _vm._e(),
                                                  item.deliveryEarlyTime !==
                                                  null
                                                    ? [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.parseTime(
                                                                item.deliveryEarlyTime
                                                              )
                                                            ) +
                                                            " - " +
                                                            _vm._s(
                                                              _vm.parseTime(
                                                                item.deliveryLateTime
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    : _vm._e(),
                                                ],
                                                2
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _c("v-page", {
                                staticClass: "pt-3 pr-3",
                                attrs: {
                                  "page-size-menu": [50, 100],
                                  "total-row": _vm.totalRecord,
                                },
                                on: { "page-change": _vm.readDataFromAPI },
                              }),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.showDetail && _vm.$vuetify.breakpoint.mdAndUp
                    ? _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "mt-0",
                              staticStyle: {
                                height: "730px",
                                "overflow-y": "auto",
                              },
                              attrs: { light: "" },
                            },
                            [
                              _c("shipment-info"),
                              _c(
                                "v-container",
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c("v-col", { attrs: { cols: "12" } }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "rounded",
                                            staticStyle: {
                                              position: "relative",
                                              border: "1px solid #d6d8d9",
                                            },
                                          },
                                          [
                                            _c("tender-detail", {
                                              attrs: { "show-edit": false },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "mt-3 rounded",
                                            staticStyle: {
                                              position: "relative",
                                              border: "1px solid #d6d8d9",
                                            },
                                          },
                                          [
                                            _c("shipper-info", {
                                              attrs: {
                                                "hgt-class": _vm.heightClass,
                                                "show-edit": false,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "mt-3 rounded",
                                            staticStyle: {
                                              position: "relative",
                                              border: "1px solid #d6d8d9",
                                            },
                                          },
                                          [
                                            _c("consignee-info", {
                                              attrs: {
                                                "hgt-class": _vm.heightClass,
                                                "show-edit": false,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "mt-3 rounded",
                                            staticStyle: {
                                              position: "relative",
                                              border: "1px solid #d6d8d9",
                                            },
                                          },
                                          [
                                            _c("commodity-dimensions", {
                                              attrs: {
                                                "hgt-class": _vm.heightClass,
                                                "show-edit": false,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "mt-3 rounded",
                                            staticStyle: {
                                              position: "relative",
                                              border: "1px solid #d6d8d9",
                                            },
                                          },
                                          [
                                            _c("carrier-info", {
                                              attrs: {
                                                "hgt-class": _vm.heightClass,
                                                "show-edit": false,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }